<template>
  <sgv-table
    :rKey="rKey"
    :items="projects"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">
    <template slot-scope="{item, hidden}">
      <tr>
        <td v-if="hidden.title">
          <router-link
            class="text-decoration-none"
            :class="{'text-danger': !item.publishedAt}"
            :to="toDetail(item.id)">
            {{ item.title }}
          </router-link>
        </td>
        <td v-if="hidden.order">
          {{ item.order }}
        </td>
        <td v-if="hidden.location">
          {{ item.location }}
        </td>
        <td v-if="hidden.area">
          {{ item.area | comma }}
        </td>
        <td v-if="hidden.publishedAt">
          {{ item.publishedAt | date }}
        </td>
      </tr>
    </template>

    <router-link
      slot="action"
      v-if="$auth.hasRole(`chronowoodthProject:${projectType}:add`)"
      :to="toDetail(0)">
      <button
        type="button"
        class="btn btn-link text-success">
        เพิ่ม
      </button>
    </router-link>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_PROJECT } from './graph/project'

export default {
  mixins: [retainMixin],
  props: {
    projectType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `ChronowoodthProject${this.$form.capitalize(this.projectType)}Detail`,
      rKey: `ChronowoodthProject${this.$form.capitalize(this.projectType)}ListCategory`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'ชื่อ', value: 'title', sort: true, filter: true},
        {text: 'ลำดับ', value: 'order', sort: true},
        {text: 'สถานที่', value: 'location', sort: true, filter: true},
        {text: 'พื้นที่', value: 'area'},
        {text: 'วันที่', value: 'publishedAt'},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['title', 'order', 'location', 'area', 'publishedAt'],
        column: null,
        search: null,
        toolbar: null,
      },
      projects: []
    }
  },
  apollo: {
    projects: {
      query () {
        return LIST_PROJECT(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          projectType: this.projectType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      const filter = {
        ...v,
        ...(v.params || {})
      }
      delete filter.order
      delete filter.params
      filter.orderBy = v.order
      return filter
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {projectId: id}
      }
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  }
}
</script>

<style lang="css" scoped>
</style>
